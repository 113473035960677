<template>
  <div style="background-color: white;padding: 0 20px; position: relative">
    <div style="height: 40px;padding-top: 20px">
      <img style="display: inline-block;height: 24px;vertical-align: middle" src="../assets/img/robot/phone_back.png" @click="goBack()">
      <span style="font-size: 18px;color: #000613;font-weight: 500;display: inline-block;vertical-align: middle;margin-left: 10px">{{ $t('footer.ul_li1') }}</span>
    </div>
    <span style="color: #8E9298; font-size: 16px; display: block; margin-top:20px">選擇問題類別</span>
    <div style="margin-top: 20px; width: 100%; text-align: center">
      <el-radio v-model="selectValue" label="投诉" border size="medium">投诉</el-radio>
      <el-radio v-model="selectValue" label="建议" border size="medium">建议</el-radio>
      <el-radio v-model="selectValue" label="故障" border size="medium">故障</el-radio>
      <el-radio v-model="selectValue" label="其他" border size="medium">其他</el-radio>
    </div>
    <span style="color: #8E9298; font-size: 16px; display: block; margin-top:20px">問題描述</span>
    <div style="position: relative; margin-top: 15px">
      <el-input style="font-size: 14px" type="textarea" :rows="6" placeholder="請輸入内容" v-model="feedbackContent" @input="totalinput($event)"></el-input>
      <span style="position:absolute; color: #C0C4C8; font-size: 12px; right: 8px;bottom: 6px">{{ thisNum }}/{{ totalNum }}</span>
    </div>
    <el-upload
        class="avatar-uploader"
        :action="upload"
        name="pic"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload"
        :on-preview="handlePictureCardPreview">
      <img v-if="imageUrl" :src="imageUrl" class="avatar">
      <div v-else class="" style="text-align: center; width: 100px; height: 100px;">
        <img style="width: 28px; height: 23px; margin-top: 25px; text-align: center" src="../assets/img/footer/add_pic.png">
        <span style="display: block;font-size: 12px;margin-top:5px;color: #C0C4C8">添加照片</span>
      </div>
    </el-upload>
    <el-button class="submit-btn" type="primary" @click="submit()">提交</el-button>
  </div>
</template>

<script>
import {feedbackRequest} from "../server/auth";
import {baseUrl} from "../utils/request";

export default {
  name: "FeedBack",
  data() {
    return {
      selectValue: '',
      feedbackContent: '',
      imageUrl: '',
      thisNum: 0,
      totalNum: 500,
      upload: baseUrl + '/other/uploadImage',
    }
  },
  created() {

  },
  mounted() {
    console.log(this.upload)
  },
  methods:{
    goBack(){
      this.$router.back()
    },
    totalinput(value){
      if (value.length>500){
        this.feedbackContent = value.substr(0, 500)
        this.$message.warning('最多只能輸入500個字符')
      }
      this.thisNum = this.feedbackContent.length
    },
    async submit(){
      if (!this.selectValue){
        this.$message.warning('请选择问题类型')
        return
      }
      if (!this.feedbackContent){
        this.$message.warning('请输入问题描述')
        return
      }
      const res = await feedbackRequest(this.selectValue, this.feedbackContent, this.imageUrl)
      if (res.success){
        this.$message.success('收到，非常感謝您提的寶貴意見！')
      } else{
        this.$message.error(res.message);
      }

    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = res.data;
      console.log(this.imageUrl)
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt12M = file.size / 1024 / 1024 < 12;

      if (!isJPG && !isPNG) {
        this.$message.error('图片只能是 JPG或者PNG 格式!');
      }
      if (!isLt12M) {
        this.$message.error('图片大小不能超过 12MB!');
      }
      return (isPNG || isJPG) && isLt12M;
    },
    handlePictureCardPreview(file,fileList) {
      console.log(12)
    }
  }
}
</script>

<style scoped>

.submit-btn{
  width: 100%;
  background-color: #AB54DB;
  position: relative;
  margin-top: 60px;
  margin-bottom: 60px;
}

>>>.el-radio__input{
  display: none;
  border-radius: 36px;
  /*padding: 10px 20px;*/

}
.is-checked {
  color: white;
  border: none;
  background-color: #AB54DB;
}

.el-radio--medium.is-bordered {
  padding: 0px;
  border-radius: 36px;
  height: 36px;
  margin: 0px auto;
  vertical-align: middle;
  width: 20%;
}
>>>.el-radio__label {
  font-size: 14px;
  vertical-align: middle;
  line-height: 36px;
  padding-left: 0px;
}

>>>.el-radio__input.is-checked+.el-radio__label {
  color: white;
}

.avatar-uploader{
  margin-top: 20px;
  width: 100px;
  height: 100px;
  border: 1px #EAECEF solid;
  border-radius: 1px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

</style>
